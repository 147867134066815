
              @import "@/assets/css/variables.scss";
            
























































































// @import '@/assets/css/pages/register/authority.scss';
// @import '@/assets/css/pages/register/registerFinish.scss';
.register_finish {
  display: flex;
  flex-direction: column;
  align-items: center;

  .svg-icon {
    font-size: 48px;
    display: block;
    margin-bottom: 24px;
  }

  .text,
  .word_title {
    font-size: 14px;
    color: $text-primary;
    line-height: 22px;
  }

  .word_title {
    margin-bottom: 16px;
  }

  .btn_box {
    margin-top: 24px;
  }
}
